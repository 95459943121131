export const stateData = [
    { alabama: 'Alabama' },
    { ak: 'Alaska' },
    { az: 'Arizona' },
    { ar: 'Arkansas' },
    { as: 'American Samoa' },
    { ca: 'California' },
    { co: 'Colorado' },
    { cnt: 'Connecticut' },
    { de: 'Delaware' },
    { dc: 'District of Columbia' },
    { florida: 'Florida' },
    { georgia: 'Georgia' },
    { guam: 'Guam' },
    { hi: 'Hawaii' },
    { id: 'Idaho' },
    { il: 'Illinois' },
    { in: 'Indiana' },
    { ia: 'Iowa' },
    { ks: 'Kansas' },
    { ky: 'Kentucky' },
    { la: 'Louisiana' },
    { me: 'Maine' },
    { md: 'Maryland' },
    { ma: 'Massachusetts' },
    { mi: 'Michigan' },
    { mn: 'Minnesota' },
    { ms: 'Mississippi' },
    { mo: 'Missouri' },
    { ne: 'Nebraska' },
    { nv: 'Nevada' },
    { nh: 'New Hampshire' },
    { nj: 'New Jersey' },
    { nm: 'New Mexico' },
    { ny: 'New York' },
    { nc: 'North Carolina' },
    { nd: 'North Dakota' },
    { oh: 'Ohio' },
    { ok: 'Oklahoma' },
    { or: 'Oregon' },
    { pa: 'Pennsylvania' },
    { pr: 'Puerto Rico' },
    { ri: 'Rhode Island' },
    { sc: 'South Carolina' },
    { sd: 'South Dakota' },
    { tn: 'Tennessee' },
    { tx: 'Texas' },
    { tt: 'Trust Territories' },
    { utah: 'Utah' },
    { vt: 'Vermont' },
    { va: 'Virginia' },
    { vi: 'Virgin Islands' },
    { watn: 'Washington' },
    { wv: 'West Virginia' },
    { wi: 'Wisconsin' },
    { wy: 'Wyoming' },
    { mt: 'Montana' },
    { mp: 'Northern Mariana Islands' }
]


export const UK = [
    { ENG: "England" },
    { NIR: "Northern Ireland" },
    { SCT: "Scotland" },
    { WLS: "Wales" },
]


export const IR = [
    { Connaught: "Connaught" },
    { Leinster: "Leinster" },
    { Munster: "Munster" },
    { Ulster: "Ulster" },
]


export const CA = [
    { AB: "Alberta" },
    { BC: "British Columbia" },
    { MB: "Manitoba" },
    { NBWK: "New Brunswick" },
    { NL: "Newfoundland and Labrador" },
    { NS: "Nova Scotia" },
    { NT: "Northwest Territories" },
    { NU: "Nunavut" },
    { ON: "Ontario" },
    { PE: "Prince Edward Island" },
    { QC: "Québec" },
    { SK: "Saskatchewan" },
    { YT: "Yukon" },
]
