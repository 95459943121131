import React, { useState, useEffect, useLayoutEffect } from "react";
import { FaFlag } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { CA, IR, UK, stateData } from "../config/state";
import { useParams, useLocation } from "react-router-dom";
import Flags from "../assets/flags.png";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { contactRef, db } from "./firebase";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkForm";
import axios from "axios";
import Logo from "../assets/logo.png";
import { Modal } from "react-responsive-modal";
import { DURATION_CONST } from "../constants";

const Orderreport = () => {
  const { country } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [isAccept, setIsAccept] = useState(false);
  const location = useLocation();

  const [state, setState] = useState({
    fullName: "",
    email: "",
    phone: "",
    vin: location.state ? location.state : "",
    country: "",
    state: "",
    package: "34",
  });
  const onChange = (e) => {
    const { value, name } = e.target;
    setState({ ...state, [name]: value });
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    setIsOpen(true);
  };
  const submitAfterAccept = (e) => {
    e.preventDefault();

    try {
      if (isAccept) {
        const cardElement = elements.getElement("card");

        stripe.createToken(cardElement).then(async (payload) => {
          axios
            .post(
              "https://inspectify-backend-production.up.railway.app/create-payment-intent",
              { token: payload, price: state.package }
            )
            .then(async (res) => {
              window.open(res.data.clientSecret.receipt_url, "_blank").focus();
              const docRef = await addDoc(collection(db, "order"), {
                fullName: state.fullName,
                email: state.email,
                phone: state.phone,
                vin: state.vin,
                country: state.country,
                state: state.state,
                package: state.package,
              });
              if (docRef.id) {
                setState({
                  fullName: "",
                  email: "",
                  phone: "",
                  vin: "",
                  country: "",
                  state: "",
                  package: "",
                });
                setIsAccept(false);
                return toast.success("Form Submitted Successfully", {
                  position: "top-center",
                });
              } else {
                return toast.error("An Error Occured", {
                  position: "top-center",
                });
              }
            })
            .catch((e) => {
              alert("something went wrong");
            });
        });
      }
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };
    

  
  const Duration = {
    "34": DURATION_CONST["12"],
    "25.0": DURATION_CONST["12"],
    "35.0": DURATION_CONST["12"],
    "42.000": DURATION_CONST["12"],

    "42": DURATION_CONST["30"],
    "35.0": DURATION_CONST["30"],
    "42.00": DURATION_CONST["30"],
    "50.00": DURATION_CONST["30"],

    "50": DURATION_CONST["60"],
    "42.0": DURATION_CONST["60"],
   "52.0": DURATION_CONST["60"],
    "60.0": DURATION_CONST["60"],


  };
  
  return (
    <div>
      <ToastContainer />

      <nav className="bg-white-300 border-white-200 light:bg-white-900">
        <div className="max-w-6xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a className="flex items-center space-x-3 rtl:space-x-reverse">
            <Link
              to="/"
              className="flex items-center self-center text-blue-700 no-underline text-3xl font-semibold"
            >
              {" "}
              <img src={Logo} className="w-20 h-auto" alt="Flowbite Logo" />
              <span className="text-orange-800" style={{ color: "orange" }}>
                Inspectify
              </span>
              Auto
            </Link>
          </a>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <div className=" w-full block  md:w-auto" id="navbar-default">
            <ul className="secondary-color font-medium flex flex-col p-4 md:p-0 mt-4 border border-white-100 rounded-lg bg-white-300 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white-300 light:bg-white-800 md:dark:bg-light-900 light:border-white-700">
              <li>
                <Link
                  to="/"
                  className="secondary-color block py-2 px-3 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-800 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/how"
                  className="secondary-color block py-2 px-3 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-800 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                >
                  How To Order
                </Link>
              </li>
              <li>
                <a
                  href="#"
                  className="secondary-color py-2 px-3 text-gray-800 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-800 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent flex items-center"
                >
                  <FaFlag className="mr-2" />
                  EN
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="max-w-6xl flex mx-auto p-16 bg-gr mt-4">
        <div class="info info">
          <div class="text-heading font-bold" data-aos="fade-down">
            <p className="heading-3  text-4xl text-color-black mb-4 lh leading-12">
              Get Full Report
              <span className="font-bold text-red-700 secondary-color">
                {" "}
                Select package{" "}
              </span>
              & Get your Vehicle History Report instantly!.
            </p>
          </div>
        </div>
      </div>

      <div className="max-w-6xl mx-auto pt-16 px-10" data-aos="flip-left">
        <form
          className="max-w-screen-xl mx-auto sm:px-4 lg:px-24"
          onSubmit={onSubmitForm}
        >
          <div className="mb-5">
            <label
              for="full name"
              className="bl-color block mb-2 text-lg font-medium text-gray-900 dark:text-white"
            >
              Full Name
            </label>
            <input
              value={state.fullName}
              onChange={onChange}
              name="fullName"
              type="text"
              id="fullName"
              className="bg-black bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Name"
              required
            />
          </div>

          <div className="mb-5">
            <label
              for="email"
              className="bl-color block mb-2 text-lg font-medium text-gray-900 dark:text-white"
            >
              Email
            </label>
            <input
              value={state.email}
              onChange={onChange}
              type="email"
              name="email"
              id="email"
              className="bg-black bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Email"
              required
            />
          </div>

          <div className="mb-5">
            <label
              for="full name"
              className="bl-color block mb-2 text-lg font-medium text-gray-900 dark:text-white"
            >
              Phone Number
            </label>
            <input
              value={state.phone}
              onChange={onChange}
              type="number"
              name="phone"
              id="phone"
              className="bg-black bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Phone Number"
              required
            />
          </div>

          <div className="mb-5">
            <label
              for="full name"
              className="bl-color block mb-2 text-lg font-medium text-gray-900 dark:text-white"
            >
              Vin
            </label>
            <input
              value={state.vin}
              onChange={onChange}
              type="text"
              name="vin"
              id="vin"
              className="bg-black bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Vin Number"
              required
            />
          </div>

          <div className="mb-5">
            <label
              for="full name"
              className="bl-color block mb-2 text-lg font-medium text-gray-900 dark:text-white"
            >
              Select Country
            </label>
            <select
              value={state.country}
              onChange={onChange}
              required
              id="countries"
              name="country"
              className="bg-black bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value=""></option>
              {country == "US" && <option value="US">United States</option>}
              {country == "UK" && <option value="UK">United Kingdom</option>}
              {country == "IR" && <option value="IR">Ireland</option>}
              {country == "CA" && <option value="CA">Canada</option>}
            </select>
          </div>

          <div className="mb-5">
            <label
              for="full name"
              className="bl-color block mb-2 text-lg font-medium text-gray-900 dark:text-white"
            >
              Select State
            </label>
            <select
              value={state.state}
              required
              onChange={onChange}
              id="state"
              name="state"
              className="bg-black bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value=""></option>
              {state.country == "US" &&
                stateData.map((item, index) => {
                  const [key, value] = Object.entries(item)[0];
                  return (
                    <option key={index} value={key}>
                      {value}
                    </option>
                  );
                })}

              {state.country == "UK" &&
                UK.map((item, index) => {
                  const [key, value] = Object.entries(item)[0];
                  return (
                    <option key={index} value={key}>
                      {value}
                    </option>
                  );
                })}

              {state.country == "IR" &&
                IR.map((item, index) => {
                  const [key, value] = Object.entries(item)[0];
                  return (
                    <option key={index} value={key}>
                      {value}
                    </option>
                  );
                })}

              {state.country == "CA" &&
                CA.map((item, index) => {
                  const [key, value] = Object.entries(item)[0];
                  return (
                    <option key={index} value={key}>
                      {value}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="mb-5">
            <label
              for="full name"
              className="bl-color block mb-2 text-lg font-medium text-gray-900 light:text-white"
            >
              Select Package
            </label>
            <select
              value={state.package}
              required
              onChange={onChange}
              id="countries"
              name="package"
              className="bg-black bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
                
              <option value="34">Car Startup - 34 USD</option>
              <option value="42">Car Instant Elite - 42 USD</option>
              <option value="50">Car Premium - 50 USD</option>
              <option value="25.0">Bike Startup - 25 USD</option>
              <option value="35.0">Bike Instant Elite - 35 USD</option>
              <option value="42.0">Bike Premium - 42 USD</option>
              <option value="35.0">Truck Startup - 35 USD</option>
              <option value="42.00">Truck Instant Elite - 42 USD</option>
              <option value="52.0">Truck Premium - 52 USD</option>
              <option value="42.000">RV Startup - 42 USD</option>
              <option value="50.00">RV Instant Elite - 50 USD</option>
              <option value="60.0">RV Premium - 60 USD</option>
            </select>
            <p>Duration:{Duration[state.package]}</p>
          </div>
          <div></div>
          {!isAccept && (
            <div>
              <button
                type="submit"
                style={{ width: "100%" }}
                className=" bg-orange text-white w-full bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm  sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              >
                Proceed
              </button>
            </div>
          )}
        </form>
        <div className="max-w-screen-xl mx-auto sm:px-4 lg:px-24">
          {isAccept && <CheckoutForm onSubmitForm={submitAfterAccept} />}
        </div>
      </div>

      <footer className="max-w-6xl mx-auto pt-16 flex justify-center">
        <div>
          <div className="flex justify-center ">
            <img src={Flags} />
          </div>
          <div className="copyrights">
            &copy; 2016 Inspectify Autos All rights reserved.
          </div>
        </div>
      </footer>

      {/* modal */}
      <Modal open={isOpen} center showCloseIcon={false}>
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div
            style={{ backgroundColor: "#012e68" }}
            className="flex bg-flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600"
          >
            <h3 className="text-xl font-semibold text-white dark:text-white">
              Terms and Conditions
            </h3>
            <button
              onClick={() => setIsOpen(false)}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="default-modal"
            >
              <svg
                className="w-3 h-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="p-4 md:p-5 space-y-4">
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              If the report was not delivered even in spam and junk folders and
              the customer claims within 48 hours, we would refund his payment.
            </p>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              If there is any type of mistake in a report and the customer
              claims within 48 hours, we will refund his payment.
            </p>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              If a customer claims within 48 hours that the transaction made was
              not by him then we will verify this by matching the mail account
              on which he requested the report and the mail account by which he
              made a payment are the same. If they are the same we won't refund
              otherwise we will refund his payment.
            </p>
          </div>

          <div
            style={{ backgroundColor: "#012e68" }}
            className="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600"
          >
            <button
              onClick={() => {
                setIsAccept(true);
                setIsOpen(false);
              }}
              data-modal-hide="default-modal"
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Accept
            </button>
            <button
              onClick={() => setIsOpen(false)}
              data-modal-hide="default-modal"
              type="button"
              className="ms-3 text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
            >
              Cancel
            </button>
          </div>
        </div>{" "}
      </Modal>
    </div>
  );
};

export default Orderreport;
