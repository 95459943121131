import logo from './logo.svg';
import './App.css';
import Home from './pages/home';
import StripeCheckout from './pages/checkForm';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link
} from "react-router-dom";
import Howtoorder from './pages/howtoorder';
import Searchpage from './pages/searchpage';
import 'react-toastify/dist/ReactToastify.css';
import Orderreport from './pages/orderreport';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import 'react-responsive-modal/styles.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

function App() {
  return (
    <Elements stripe={stripePromise}>

    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/how' element={<Howtoorder />} />
        <Route path='/search' element={<Searchpage />} />
        <Route path='/order-report/:country' element={<Orderreport />} />


      </Routes>
    </Router>
    </Elements>

  );
}

export default App;
